import React from 'react'
import { AiOutlineStar } from 'react-icons/ai'
import { FaStar } from "react-icons/fa";

const Starts = (props) => {
    return (
        <div className='flex text-yellow-500'>
            {
                [...new Array(5)].map((star, index) => {
                    return index < props.score ? <FaStar /> : <AiOutlineStar />
                })
            }
        </div>
    )
}

export default Starts